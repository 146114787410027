import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="" />
        <Row className="about-wrapper">
          <Col sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  GMAS 462 BV <br />
                  Overheulestraat 119 <br />
                  8560 Moorsele (Wevelgem)
                </p>
                <p className="about-wrapper__info-text">
                  <a href="mailto:info@gmas.be">info@gmas.be</a>
                  <br />
                  <a href="tel:+32474321433">+32 474 32 14 33</a>
                </p>
                <p className="about-wrapper__info-text">BE 0795.492.545</p>
                <p className="about-wrapper__info-text">KBC BE87 7370 6699 3394</p>

                <p className="about-wrapper__info-text" />

                <p className="about-wrapper__info-text">
                  video - streaming facilities - technical event support
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
